<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="aligncenter wp-image-22724" src="@/assets/news/1/1.jpg" alt="" width="581" height="387" sizes="(max-width: 581px) 100vw, 581px"></p>
        <p>加拿大发布《2021-2023年移民计划》，准备接收有史以来超高人数的新移民，在未来3年吸纳超过123万移民：</p>
        <p>2021年：预计接收永久居民40.1万</p>
        <p>2022年：预计接收永久居民41.1万</p>
        <p>2023年：预计接收永久居民42.1万</p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-22721" src="@/assets/news/1/2.jpg" alt="" width="628" height="555"></p>
        <p>移民部长门迪奇诺(Mendicin)也表示，加拿大将加快接受新移民的进度。</p>
        <p>广纳移民的加拿大，令不少投资人心驰神往。其中，安大略省则是高净值人群青睐的省份之一，这里经济发达，拥有大城市多伦多和渥太华，华人众多，生活便利。</p>
        <p>2019年7月8日，为了安大略省企业家移民的项目更有吸引力，安省政府重新修订了安省企业家移民的政策，大多伦多以内地区的投资额从100w加币降为60w加币，大多伦多以外地区的投资额从50w加币降为20w加币，因此安大略省企业家移民项目也是投资人的热门选择之一。</p>
        <p><strong>安大略省企业家移民项目</strong></p>
        <p>安大略省，简称安省，是加拿大的经济、金融中心，人口密集，同时有加拿大心脏之称。加拿大首都渥太华位于安大略，省会多伦多是加拿大超大的城市。滑铁卢大学、多伦多大学、女皇大学、西安大略大学等学府也聚居在这里。</p>
        <p><img loading="lazy" class="aligncenter wp-image-22735" src="@/assets/news/1/3.jpg" alt="" width="642" height="420"  sizes="(max-width: 642px) 100vw, 642px"></p>
        <p>安省对移民友善，且扶持力度也很大，安省是新移民在加拿大定居的第一大省，每年超过10万人选择安省。</p>
        <p>有兴趣在安大略省定居、有一定经济实力和管理经验的企业家或高管，可以通过在安大略省新建企业或者收购安大略省现有企业，申请加拿大永久居民身份。</p>
        <p>那么，如何通过企业家项目移民安大略省呢？</p>
        <p><strong>申请条件</strong></p>
        <p><strong>商业要求</strong></p>
        <p>五年内有两年的企业主（持股 33.3% 以上）/ 高管经验</p>
        <p><strong>个人资产</strong></p>
        <p>如果拟经营公司位于</p>
        <p>a.大多伦多地区（包括多伦多市、达勒姆、哈尔顿、约克和皮尔地区），申请人必须拥有至少80万加元的净资产</p>
        <p>b.大多伦多地区以外，申请人必须拥有至少40万加元的净资产</p>
        <p>c.如果业务范围是在信息和通讯技术/ 数字通信领域，不论地区，申请人都必须拥有至少40万加元的净资产</p>
        <p><strong>投资要求</strong></p>
        <p>如果拟经营公司位于</p>
        <p>a.大多伦多地区，申请人必须至少投资60 万加元，并且持股三分之一以上</p>
        <p>b.大多伦多地区以外，申请人必须至少投资20 万加元，并且持股三分之一以上</p>
        <p>c.如果业务范围是在信息和通讯技术/ 数字通信领域，不论地区，申请人都必须至少投资20 万加元，并且持股三分之一以上</p>
        <p><strong>创造就业</strong></p>
        <p>如果拟经营公司位于</p>
        <p>a.大多伦多地区，申请人必须为加拿大公民/ 永久居民创造至少两个永久性的全职工作</p>
        <p>b.大多伦多地区以外，或者是信息和通讯技术/ 数字通信领域的企业，申请人必须为加拿大公民/ 永久居民创造至少一个永久性的全职工作</p>
        <p>c.职位必须在建立或收购企业后的前20个月内创造，职员必须连续工作至少10个月，并必须按该职位的工资中位数支付工资</p>
        <p><strong>如果计划购买现成的企业</strong></p>
        <p>a. 申请人提交EOI 之前的12 个月内需要到安省进行一次商务考察</p>
        <p>b. 被收购的企业必须由同一所有者在过去60 个月之内持续经营</p>
        <p>c. 企业的所有权必须完全转让给申请人或任何业务伙伴</p>
        <p>d. 申请人必须至少将个人投资的10% 用于改善或扩大在安省的业务</p>
        <p>e. 申请人必须保留转让前在公司工作的永久全职员工</p>
        <p><strong>工签转永居要求</strong></p>
        <p>1.语言达到CLB 4或以上（雅思听 4.5，说 4.0，读 3.5，写 4.0）</p>
        <p>2.申请人1年内有至少9个月的时间居住在安省</p>
        <p>3.申请人必须积极参与企业每日管理</p>
        <p>4.申请人和企业在登陆20个月之内必须满足安省Performance Agreement的要求，包括企业地址、投资金额、创造职位数量等。所有协议要求都是基于EOI 打分和商业计划而签订的。</p>
        <p>&nbsp;</p>
        <p><strong>工签申请流程</strong></p>
        <p style="text-align: center;">商务考察</p>
        <p style="text-align: center;">↓</p>
        <p style="text-align: center;">制定商业计划Business Plan</p>
        <p style="text-align: center;">↓</p>
        <p style="text-align: center;">递交EOI</p>
        <p style="text-align: center;">↓</p>
        <p style="text-align: center;">收到LAA 邀请信</p>
        <p style="text-align: center;">↓</p>
        <p style="text-align: center;">递交全套材料和第三方机构评估文件</p>
        <p style="text-align: center;">↓</p>
        <p style="text-align: center;">省移民局审核</p>
        <p style="text-align: center;">面试签订Performance Agreement</p>
        <p style="text-align: center;">↓</p>
        <p style="text-align: center;">获得工签支持信/ 申请工作签证</p>
        <p style="text-align: center;">↓</p>
        <p style="text-align: center;">获得工签，登陆安省，建立企业</p>
        <p><strong>简言之，只需两步就可完成移民：</strong></p>
        <p>第一步：进行商务考察（可选），递交EOI和商业计划，向省政府递交申请，参加面试并与省政府签署商业协议，获得工签后登陆加拿大。</p>
        <p>第二步：申请人及家属持工作签证登陆加拿大，完成商业计划，获得省提名许可后，向联邦政府提交永久居民申请等待通过。</p>
        <p><strong>企业家移民评分系统EOI</strong></p>
        <p>加拿大安省企业家商业移民EOI打分表适用于申请加拿大安省商业移民类的企业家类别。</p>
        <p>打分表满分200，其中个人评分占126分，商业理念占74分。</p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-22782" src="@/assets/news/1/4.jpg" alt="" width="504" height="798"></p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-22781" src="@/assets/news/1/5.jpg" alt="" width="516" height="821"></p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-22780" src="@/assets/news/1/6.jpg" alt="" width="500" height="236"></p>
        <p>EOI打分表</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news1",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:1,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>